@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap");

* {
  font-family: "Roboto Slab", serif;
  padding: 0%;
  margin: 0%;
}

.ControlpanelApp {
  margin-bottom: 100px;
}

#controlpanel-header {
  padding: 20px;
  text-align: center;
  color: white; 
  font-weight: lighter;
}

.controlpanel-h2 {
  text-align: center;
  padding: 20px;
}

.nav {
  background-color: #444141;
  width: 100%;
}

.btn-cont {
  display: flex;
  justify-content: space-evenly;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.nav-but {
  padding: 5px;
  background-color: #444141;
  border: none;
  color: white;
}

.nav-but:hover {
  cursor: pointer;
  color: #66CCCC;
}

.form-comp {
  width: clamp(200px, 60vw, 700px);
  margin-left: auto;
  margin-right: auto;
}

.open-but {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  text-align: left;
}

.open-but:hover {
  cursor: pointer;
}

.response-text {
  position: fixed;
  padding: 30px;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  width: 250px;
  top: 45%;
  left: 0;
  right: 0;
  margin-left: auto; 
  margin-right: auto; 
}

.gameform, .moduleform, .chapterform, .questionform {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.form-child {
  margin: 10px;
  padding: 5px;
  width: clamp(180px, 40vw, 400px);
}

.sub-but {
  padding: 5px; 
}

.list-child {
  width: 100%;
  text-align: left;
  list-style-type: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.list, #gamelist, #modulelist, #chapterlist, #questionlist {
  width: 100%;
  border: solid 1px gray;
}

.list-child:nth-child(odd) {
  background-color: lightgray;
}

.list-child:nth-child(even) {
  background-color: white;
}

#modulelist, #chapterlist, #questionlist {
  margin-top: 20px;
  max-height: 500px;
  overflow-y: scroll;
}

select {
  margin-bottom: 20px;
}

.list-opts {
  font-size: small;
  margin-top: 10px;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.modal {
  position: fixed;
  z-index: 1;
  overflow: auto;
  width: 100%; 
  height: 100%;
  top: 0;
  left: 0;
  background-color: #d3d3d357;
  justify-content: center;
} 

.modal-content{
  background-color: white;
  margin: auto;
  padding: 2rem;
  border-radius: 1.2rem;
  width: 80%;
}

.modal-header{
  display: flex;
  justify-content: space-between;
}

.between{
  justify-content: space-between;
  display: flex;
}