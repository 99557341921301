@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap);
* {
  font-family: "Roboto Slab", serif;
  padding: 0;
  margin: 0;
}

html,
body {
  background-color: white;
}

.component {
  position: relative;
  min-height: 60vh;
  margin-bottom: 5vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.options {
  overflow-x: hidden;
  text-align: center;
  width: 100vw;
  min-width: 150px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 10vh;
}

.menubtn {
  width: clamp(100px, 40vw, 200px);
  min-height: 50px;
  height: 10%;
  font-size: small;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: lightgray;
  color: black;
}

.opt,
.quizbtn {
  width: clamp(200px, 60vw, 400px);
  padding: calc(5px + 1vw);
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow-wrap: break-word;
}

.opt {
  color: black;
}

.App {
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.back {
  background-color: gray;
  color: white;
  width: clamp(100px, 40vw, 200px);
  min-height: 50px;
  height: 10%;
  font-size: small;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#next,
#finish,
#check {
  background-color: #409393;
  color: white;
}

.stat {
  margin: 20px;
}

.Quiz {
  margin-top: 6vh;
  overflow-x: hidden;
  text-align: center;
}

#restart {
  padding: 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  background-color: lightgray;
}

.header3 {
  margin-bottom: 5vh;
}

#op {
  margin: 1vh;
}

#logo {
  font-size: calc(12px + 1.5vw);
  margin-top: calc(10px + 2vh);
  text-align: center;
}

#stop {
  color: white;
  background-color: gray;
}

.icons {
  margin-top: 15px;
  margin-bottom: 15px;
  width: clamp(80px, 40%, 100px);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  display: inline-flex;
  justify-content: space-around;
}

.icon,
#quizinfo {
  height: clamp(25px, 2vw, 40px);
  width: clamp(25px, 2vw, 40px);
  border-radius: 50%;
  background: transparent;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
}

#quizinfo {
  border: blue 2px solid;
  color: blue;
}

#quizinfo:hover {
  background: blue;
  color: white;
  cursor: pointer;
}

#plus {
  border: #409393 2px solid;
  color: #409393;
}

#plus:hover {
  cursor: pointer;
  background: #409393;
  color: white;
}

#cross {
  border: red 2px solid;
  color: red;
}

#cross:hover {
  cursor: pointer;
  background: red;
  color: white;
}

#question {
  display: inline-block;
  width: clamp(200px, 70vw, 500px);
}

.breadcrumbs {
  overflow: hidden;
  position: absolute;
  top: 1vh;
  left: 1vw;
  bottom: 2vh;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.crumb {
  float: left;
  position: relative;
  font-size: calc(7px + 1vw);
  color: #409393;
}

.qList {
  font-size: 0.8pc;
  text-align: left;
  width: clamp(270px, 60%, 500px);
  margin-bottom: 20px;
  max-height: 300px;
  overflow-y: scroll;
  background-color: lightgray;
}

li {
  list-style-type: none;
}

.delStar {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  background: red;
  border: none;
  color: white;
  float: inline-end;
  margin-right: 1vw;
  margin-top: 1vh;
  font-weight: 900;
  font-family: Arial, Helvetica, sans-serif;
}

.listItem {
  list-style-type: none;
  padding: 5px;
}

.listItem button {
  margin: 5px;
  float: inline-start;
}

.listItem button:hover {
  cursor: pointer;
}

.listItem:nth-child(odd) {
  background-color: lightgray;
}

.listItem:nth-child(even) {
  background-color: white;
}

#buttonContainer {
  display: grid;
  grid-template-columns: auto auto;
  padding: 10px;
  width: 90%;
  justify-content: center;
  grid-column-gap: calc(1px+1%);
  -webkit-column-gap: calc(1px+1%);
          column-gap: calc(1px+1%);
  max-height: 300px;
}

#buttonContainer button {
  /* font-size: calc(1px+1%); */
}

.info-button {
  position: absolute;
  right: 2vw;
  top: 2vh;
  width: clamp(30px, 5vw, 40px);
  height: clamp(30px, 5vw, 40px);
  border-radius: 50%;
  background-color: lightskyblue;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: none;
}

.info-button:hover {
  background-color: skyblue;
  cursor: pointer;
}

.info-text {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  z-index: 1;
  width: clamp(200px, 20vw, 400px);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  top: 11vh;
  right: 5vw;
  display: none;
  text-align: left;
  border-radius: 5px;
}

.info-text p {
  font-size: smaller;
  padding: 10px;
}

#quiz-info-text {
  top: 19vh;
}

.show-starred-optionlist {
  border-radius: 2px;
  border: 1px black solid;
  padding: 2px;
  font-size: smaller;
}

.starred-optionlist li {
  padding-top: 10px;
}

#feedback {
  position: absolute;
  top: 0%;
}

.stat-header {
  width: clamp(200px, 70vw, 500px);
  color: #409393;
}

#fb {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 20px;
  border-radius: 20px;
  color: white;
  width: 250px;
}

.homeButton {
  width: clamp(30px, 5vw, 40px);
  height: clamp(30px, 5vw, 40px);
  border-radius: 5px;
  padding: 1px;
  background-color: #409393;
  border: none;
  position: absolute;
  top: 2vh;
  left: 2vw;
  bottom: 2vh;
}

.homeButton:hover {
  cursor: pointer;
}

.home-icon {
  width: 70%;
}

* {
  font-family: "Roboto Slab", serif;
  padding: 0%;
  margin: 0%;
}

.ControlpanelApp {
  margin-bottom: 100px;
}

#controlpanel-header {
  padding: 20px;
  text-align: center;
  color: white; 
  font-weight: lighter;
}

.controlpanel-h2 {
  text-align: center;
  padding: 20px;
}

.nav {
  background-color: #444141;
  width: 100%;
}

.btn-cont {
  display: flex;
  justify-content: space-evenly;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
}

.nav-but {
  padding: 5px;
  background-color: #444141;
  border: none;
  color: white;
}

.nav-but:hover {
  cursor: pointer;
  color: #66CCCC;
}

.form-comp {
  width: clamp(200px, 60vw, 700px);
  margin-left: auto;
  margin-right: auto;
}

.open-but {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  text-align: left;
}

.open-but:hover {
  cursor: pointer;
}

.response-text {
  position: fixed;
  padding: 30px;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 20px;
  width: 250px;
  top: 45%;
  left: 0;
  right: 0;
  margin-left: auto; 
  margin-right: auto; 
}

.gameform, .moduleform, .chapterform, .questionform {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.form-child {
  margin: 10px;
  padding: 5px;
  width: clamp(180px, 40vw, 400px);
}

.sub-but {
  padding: 5px; 
}

.list-child {
  width: 100%;
  text-align: left;
  list-style-type: none;
  padding-top: 5px;
  padding-bottom: 5px;
}

.list, #gamelist, #modulelist, #chapterlist, #questionlist {
  width: 100%;
  border: solid 1px gray;
}

.list-child:nth-child(odd) {
  background-color: lightgray;
}

.list-child:nth-child(even) {
  background-color: white;
}

#modulelist, #chapterlist, #questionlist {
  margin-top: 20px;
  max-height: 500px;
  overflow-y: scroll;
}

select {
  margin-bottom: 20px;
}

.list-opts {
  font-size: small;
  margin-top: 10px;
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.modal {
  position: fixed;
  z-index: 1;
  overflow: auto;
  width: 100%; 
  height: 100%;
  top: 0;
  left: 0;
  background-color: #d3d3d357;
  justify-content: center;
} 

.modal-content{
  background-color: white;
  margin: auto;
  padding: 2rem;
  border-radius: 1.2rem;
  width: 80%;
}

.modal-header{
  display: flex;
  justify-content: space-between;
}

.between{
  justify-content: space-between;
  display: flex;
}
